.App,
body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  height: 100vh;
  transition: background-color 0.7s ease-in-out, color 0.7s ease-in-out;
}

html,
body {
  overflow-x: hidden;
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-track {
  background: rgb(0, 122, 255, 0.1);
  padding-top: 55px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: rgb(0, 122, 255, 0.2);
}

.ms-List-cell {
  min-height: 33px !important;
}

#theme-toggle {
  visibility: hidden;
}

#theme-toggle + label {
  content: "";
  display: inline-block;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: all 0.6s ease-in-out;
  margin-top: 25px;
}

#theme-toggle:not(:checked) + label {
  background-color: gold;
}

#theme-toggle:checked + label {
  background-color: transparent;
  box-shadow: inset -9px -8px 1px 1px #fff;
}

[role="row"] {
  padding: 0;
  background-color: transparent;
  color: "white";
}

[role="row"]:hover {
  padding: 0;
  background-color: transparent;
}

[role="columnheader"] {
  color: white;
}
